import React from "react";
import { facebookAppId } from "./index";

export default class LikeButton extends React.Component<{
  encodedUrl: string;
}> {
  shouldComponentUpdate(nextProps) {
    return nextProps.encodedUrl !== this.props.encodedUrl;
  }

  render() {
    return (
      <iframe
        key="iframe"
        src={`https://www.facebook.com/plugins/share_button.php?href=${this.props.encodedUrl}&layout=button_count&size=large&mobile_iframe=false&appId=${facebookAppId}&width=110&height=32`}
        width="110"
        height="32"
        style={{
          border: "none",
          overflow: "hidden",
        }}
        scrolling="no"
        frameBorder="0"
        allowtransparency="true"
      />
    );
  }
}
